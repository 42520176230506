import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
// css
import "./about.css";
import advertisment from "../../Icons/jeep_ad.jpeg";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Row>
          {/* <Col className="about_col">
            <div className="about_game">
              <h2>About Game</h2>
              <p>
                Lorem was popularised in the 1960s with the release of Letraset
                sheet.
              </p>
              <h2>Hints</h2>
              <p>
                Lorem was popularised in the 1960s with the release of Letraset
                sheet.
              </p>
              <h2>Lorem Ipsum</h2>
              <p>
                Lorem was popularised in the 1960s with the release of Letraset
                sheet. Popularised in the 1960s.
              </p>
            </div>
          </Col> */}
          <div className="mb-30 advertisment_img">
            <img src={advertisment} />
          </div>
        </Row>
      </div>
    );
  }
}

export default About;
