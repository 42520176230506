import React from "react";
//import ReactDOM from "react-dom";


class Instruction extends React.Component{
    
    render(){
        return(
            <div>
                {/* Trigger the modal with a button */}
                {/* <button type="button" className="btn btn-info btn-lg" ></button> */}
                <i className="fa fa-info-circle iinstruction" data-toggle="modal" data-target="#myModal" aria-hidden="true"></i>
                    <div id="myModal" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                         {/* <div className="modal-header">
                         <b className="modal-title">How to play</b>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                           
                        </div> */}
                        <div className="modal-body">
                         <button type="button" className="close" data-dismiss="modal">&times;</button>
                         <b>How to play</b> Lorem was popularised in the 1960s with the release of Letraset sheet. Popularised in the 1960s
                         
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        </div> */}
                        </div>

                    </div>
                    </div>
            </div>
        );
    }
}

export default Instruction;