import React from "react";
//import ReactDOM from "react-dom";
import Leaderboard from "../leaderboard/leaderboard"

class TopPlayer extends React.Component{
    
    render(){
        return(
            <div>
                
                <i className="fa fa-universal-access player"  aria-hidden="true" data-toggle="modal" data-target="#topPlayer"></i>

                   <div id="topPlayer" className="modal fade" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                        {/* <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            {/* <h4 className="modal-title">Modal Header</h4> 
                        </div> */}
                        <div className="modal-body">
                                 <button type="button" className="close" data-dismiss="modal">&times;</button>
                           <Leaderboard />
                           
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        </div> */}
                        </div>

                    </div>
                    </div>
            </div>
        );
    }
}

export default TopPlayer;