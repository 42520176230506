import React, { Component } from 'react'; 
import { Container, Media, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, img, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import Switch from "react-switch";
// css
import './header.css'
import { Button, Input, Row, Col, Tooltip } from "reactstrap";
// icons
import logo from '../../Icons/logo.png';
import avatar from '../../Icons/avatar.png';
import guest from '../../Icons/guest.png';
import dayIcon from '../../Icons/dayIcon.png';
import nightIcon from '../../Icons/nightIcon.png';
import firebase from 'firebase'
import { auth, ref } from '../../client';
import $ from 'jquery';
import baseUrl from '../../environment/config.js';
import Instruction from './instruction';
import TopPlayer from './topPlayer';

class Header extends Component{
	constructor(props) {
		super(props);
		this.provider = '';
		this.userName='';
		this.userEmail='';
	    this.state = { 
	    	checked: true,
	    	user: null
	    };
    	this.handleChange = this.handleChange.bind(this);
	}
	 

   
	fbProvider(){
		this.provider = new firebase.auth.FacebookAuthProvider();
		this.login();
	}
	googleProvider(){
		this.provider = new auth.GoogleAuthProvider();
		this.login();
	}
	login = () => {
		
		auth().signInWithPopup(this.provider).then((result) => {
				
			let user = result.user;
			let accessToken = result.credential.accessToken;
			localStorage.setItem('accessToken', result.credential.accessToken);
			localStorage.setItem('user', JSON.stringify(user));
			
			
			var userDetailObj = {
				accessToken: result.credential.accessToken,
				displayName: user.displayName,
				email: user.email,
				phoneNumber: user.phoneNumber,
				profilePic: user.photoURL,
				uid: result.additionalUserInfo.profile.id,
				//firstName: result.additionalUserInfo.profile.first_name,
				//lastName: result.additionalUserInfo.profile.last_name,
				
				gameCount:JSON.parse(localStorage.getItem('gameCount')) || 0,
				highScore: JSON.parse(localStorage.getItem('highScore')) || 0,
				// gameScore:{
				// 	level: JSON.parse(localStorage.getItem('level')) || 1,
				// 	score: JSON.parse(localStorage.getItem('score')) || 1

				// }
			};
			localStorage.setItem('userLoggedIn', JSON.stringify(userDetailObj));
			this.userName = userDetailObj['displayName'];
			this.userEmail = userDetailObj['email'];
			
			ref.push(userDetailObj);
    		this.setState({user})
    		$('.equationBox').load(baseUrl);
  		}, err =>{
  			console.log(err)
  		});
  	}
  	logout = () => {
    	auth().signOut().then(() => {
    		localStorage.clear();
      		this.setState({user: null})
      		$('.equationBox').load(baseUrl);
    	}, err =>{
    		console.log(err)
    	});
  	}
	handleChange(checked) {
	    this.setState({ checked: !this.state.checked });
	    this.props.onChange('isDay', this.state.checked);
	}
	componentDidMount() {
		var user = JSON.parse(localStorage.getItem('user'));
		
		// this.userName = user['displayName']||null;
		// this.userEmail = user['email']||null;
		
		if(user){
			 this.setState({ user })
			
			this.userName = user['displayName'];
			 this.userEmail = user['email'];
		}
	}
	render(){
		const { user } = this.state
		return(
			<div>
				<Container>
			        <Navbar expand="sm">
			          	<NavbarBrand href="/" className="logo_outer">
			          		<img src={logo} className="logo" alt="logo" />
			          	</NavbarBrand>
			          	<Nav className="ml-auto" navbar>
			          		<NavItem>
							  	{/* <div className="toggle_switch" checked={this.state.checked} onClick={this.handleChange}>
									<input type="checkbox" className="switch_3" />
									<svg className="checkbox" viewBox="0 0 168 80">
										<path className="outer-ring" d="M41.534 9h88.932c17.51 0 31.724 13.658 31.724 30.482 0 16.823-14.215 30.48-31.724 30.48H41.534c-17.51 0-31.724-13.657-31.724-30.48C9.81 22.658 24.025 9 41.534 9z" fill="none" stroke="#d1d1d1" strokeWidth="3" strokeLinecap="square" strokeMiterlimit="3"/>
										<path className="is_checked" d="M17 39.482c0-12.694 10.306-23 23-23s23 10.306 23 23-10.306 23-23 23-23-10.306-23-23z"/>
										<path className="is_unchecked" d="M132.77 22.348c7.705 10.695 5.286 25.617-5.417 33.327-2.567 1.85-5.38 3.116-8.288 3.812 7.977 5.03 18.54 5.024 26.668-.83 10.695-7.706 13.122-22.634 5.418-33.33-5.855-8.127-15.88-11.474-25.04-9.23 2.538 1.582 4.806 3.676 6.66 6.25z"/>
									</svg>
								</div> */}
								<Instruction />
								<TopPlayer />
								
								<Switch height={18} width={46} checked={this.state.checked} onChange={this.handleChange}
									uncheckedIcon={
									<div
										style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "100%",
										fontSize: 15,
										color: "orange",
										paddingRight: 2,
										}}
									>
										<img src={nightIcon} />
									</div>
									}
									checkedIcon={
									<svg className="light_icon" viewBox="0 0 10 10" height="100%" width="100%" fill="yellow">
										<circle r={4} cx={2.5} cy={4} />
									</svg>
									}
									className="react-switch"
									id="icon-switch"
								/>
				            </NavItem>
		              		<UncontrolledDropdown nav inNavbar>
				                <DropdownToggle nav caret>
								{this.state.user == null ? <img src={guest} className="avatar_img" alt="Guest" /> : <img src={this.state.user.photoURL} className="avatar_img" alt="Guest" /> }
								
				                </DropdownToggle>
				                <DropdownMenu right>
				                	{this.state.user == null ? 
				                		<DropdownItem>
											Login with:
				                    		<div>
											<button className='btn btn-default' onClick = {()=>this.fbProvider()}>facebook</button>
											<button className='btn btn-default' onClick= {()=>this.googleProvider()}>google</button>
											</div>
				                  		</DropdownItem>
				                  		:
				                  		<DropdownItem >
				                    		<div>
												<div className="user-image-main">
												<Col className="user_image">
													<img src={this.state.user.photoURL} className="avatar_img" alt="Guest" />
												</Col>
												</div>
												<div className="user-detail-main">
												<Col className="user_name">
													{this.userName}
												</Col>
												<Col className= "user_email">
													{this.userEmail}
												</Col>
												</div>
											</div>
											
											<a onClick={this.logout}>Logout</a>
											{/* <a onClick = {()=>this.props.showProfile()}>Profile</a> */}
				                  		</DropdownItem>
				                	}
				                </DropdownMenu>
				            </UncontrolledDropdown>
		            	</Nav>
			        </Navbar>
		        </Container>
		    </div>
		);
	}
}
export default Header;