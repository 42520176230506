import React, { Component } from "react";
import { Button, Input, Container, Row, Col, Tooltip } from "reactstrap";
// Components
import Header from "./components/header/header.js";
import Instruction from "./components/header/instruction.js"
// import Equationbox from './components/equationbox/equationbox.js';
import About from "./components/about/about.js";
import Leaderboard from "./components/leaderboard/leaderboard.js";
// import Equationbox2 from './components/equationbox2/equationbox2.js';
//import Equationbox from "./components/equationbox_gridLayout/equationbox.js";
//import NewEquationbox from "./components/equationbox_gridLayout/newEquationbox";
// css
import Equationbox from "./components/equationbox_gridLayout/equationbox.js";
import Fullscreen from 'react-full-screen';
import "./App.css";
import $ from 'jquery';

class App extends Component {
  constructor(props) {
    super(props);
    this.toggleRank = this.toggleRank.bind(this);
    this.toggleTimesPlayed = this.toggleTimesPlayed.bind(this);
    this.startPop=true;
    this.isPlay= true;
    this.time='';
    this.gameOver = false;
    this.state = {
      isDay: false,
      tooltipOpenRank: false,
      tooltipOpenTimesPlayed: false,
      showpopup:true,
      conf:true,
      showLeader : false,
      showInstruction : false,
      isFull:true,
      showProf: false
    };
    this.val = "";
    this.handleKeyboardFunction = this.handleKeyboardFunction.bind(this);
  }



  componentDidMount(){
    

    
    window.addEventListener('keydown',this.handleKeyboardFunction);
    
		
	}
  
	componentWillUnmount() {
		
		window.removeEventListener('keydown',this.handleKeyboardFunction)
	}
  
  handleKeyboardFunction(e){
    if(this.gameOver === true && e.key === 'Enter'){
      this.gameOver= false;

      e.preventDefault();
      this.handleContinue();
      
     }
  }

  onChange(field, value) {
    
    // parent class change handler is always called with field name and value
    this.setState({ isDay: value });
  }
  toggleRank() {
    this.setState({
      tooltipOpenRank: !this.state.tooltipOpenRank
    });
  }
  toggleTimesPlayed() {
    this.setState({
      tooltipOpenTimesPlayed: !this.state.tooltipOpenTimesPlayed
    });
  }
 
  handleContinue(){
   
    this.val.showPop=false;
    this.startPop=false; 
    this.setState({showpopup:false})
    //this.child.startGame();
    
  }
  
  myCallFromChild(dataFromChild) {
    
    this.val = dataFromChild;
    this.setState({
      showpopup:this.val.showPop,
      
    })
    
    this.setState(this.state);
   
  }
hideProfile(){
  this.setState({showProf: !this.state.showProf})
}
  showProfile(){
    
    var user = JSON.stringify(localStorage.getItem('user'))
    
    return(
      <div>
        <div className='user_profile'>{user['displayName']} Profile</div>
        <div> Name = {user['displayName']}</div>
        <div> E-mail = {user['email']}</div>
        <div> Highest Score= {user['highScore']}</div>
        <button onClick = {()=> this.hideProfile()}>Cancle</button>
      </div>
    )
  }
  gameOverFunction(){
    this.gameOver = true;
    return(
      <div>
        <div className="game_over">Game Over!</div>
        <div> Level =  {this.val.level}</div>
        <div>Score = {this.val.score}</div>
        <div> Your High Score = {this.val.highScore}</div>
        <button className="btn btn-default mt-3" onClick={()=>this.handleContinue()}>Reload</button>
      </div>
    )
  }
 
  render() {
    const ProgressBar = (props) => {
			
			return (
				<div className="progress-bar">
					<Filler percentage={props.percentage} />
				</div>
			)
		}
		const Filler = (props) => {
			return <div className="filler" style={{ width: `${props.percentage}%` }} />
		}
    return (
    //   <Fullscreen
    //   enabled={this.state.isFull}
    //   //onChange={isFull => this.setState({isFull})}
    // >
      <div className={this.state.isDay ? "body_bg_day" : "body_bg_night"}>
      
								
        <div>
          <Header onChange={this.onChange.bind(this)} 
            showProfile= {this.hideProfile.bind(this)}
          />
          <Container className="main_container">
            <Row className="mb-2 d-flex justify-center align-items-center hide-mobile">
            <Col>
              <h1 className="d-inline-flex mb-0">EQUATER</h1>
             </Col>
            </Row>
            <Row className="">
              <Col md="7 no-padding-mobile-view"  >
                <div className="container rankboard_container">
                  <Row>
                    <Col>
                      <div className="rankboard">
                        <Row>
                        <Col className="hide-desktop">
                            <h1 className="d-inline-flex mb-0 ">EQUATER</h1>
                        </Col>
                          <Col className="levels_col">
                            <div className="players_level">
                              <div className="title">Level</div>
                              <div className="numeric_value">
                                {this.val.level}
                                
                              </div>
                            </div>
                          </Col>
                          <Col className="levels_col hide-desktop">
                            <div className="game_timer">
                              <div className="title">Timer</div>
                              <div className="numeric_value">
                                {this.val.countDownTimer}s
                               
                                   {this.val.startPop ===true   ? null:  <div ><button className="play-pause-btn uim-btn-play hide-pause" onClick={(e) => this.child.playPause(e)}></button> </div>}
                                 
                              </div>
                            </div>
                          </Col>
                          <Col className="palyed_col col-2">
                            <div className="played_times">
                              <div className="title">Score</div>
                              {this.val.score ? (
                                <div className="numeric_value">
                                  {this.val.score}
                                  {/* <span>times</span> */}
                                  
                                </div>
                              ) : (
                                <div>
                                  <div
                                    className="numeric_value"
                                    href="#"
                                    id="TooltipTimesPlayed"
                                  >
                                    0
                                  </div>
                                  <Tooltip
                                    placement="top"
                                    isOpen={this.state.tooltipOpenTimesPlayed}
                                    target="TooltipTimesPlayed"
                                    toggle={this.toggleTimesPlayed}
                                  >
                                    Please login to save progress and see how
                                    many times you played.
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          </Col>
                          <Col className="rank_col">
                            <div className="players_rank">
                              {/* <span className="layercount-temp">{this.val.gameLayerCount}</span> */}
                              <div className="title">Rank</div>
                              {this.val.rank ? (
                                <div className="numeric_value">
                                  {this.val.rank}
                                </div>
                              ) : (
                                <div>
                                  <div
                                    className="numeric_value"
                                    href="#"
                                    id="TooltipRank"
                                  >
                                    ?
                                  </div>
                                  <Tooltip
                                    placement="top"
                                    isOpen={this.state.tooltipOpenRank}
                                    target="TooltipRank"
                                    toggle={this.toggleRank}
                                  >
                                    Please login to save progress and see your
                                    rank.
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="">
                {this.state.showProf===true ? <div className="container_game_over"><div className="equatiobox-outer mb-30 ">
                {this.showProfile}
                </div></div>: null}
                 {  this.val.showPop===true ? <div className="container_game_over"><div className="equatiobox-outer mb-30 ">
                   {this.gameOverFunction()}
                 </div> </div>
                   : <div><div className="progress-bar-container-wrap equationbox_container">
                            <Row className="d-flex justify-center align-items-center">
                               <Col className="text-center progressbar_outer">
 
                                  <ProgressBar percentage={this.val.percentSlider} />
                       
                                </Col>
                               <button className="play-pause-btn uim-btn-play" onClick={(e) => this.child.playPause(e)}></button>
                             </Row>
                 </div> 
                   <Equationbox
                    callbackFromParent={this.myCallFromChild.bind(this)} 
                     onRef={ref => (this.child = ref)}
                     startPopGame={this.startPop}
                     
                  /></div>}
                  
                </div>
                

              </Col>
              
              <Col md="5 hide-mobile">
                <Row>
                  <Col xl="12" md="12">
                    <About />
                  </Col>
                  <Col className="leaderboard_col" xl="12" md="12">
                    <Leaderboard />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        
      </div>
      // </Fullscreen>
    );
  }
}

export default App;
