import React, {
	Component
} from 'react';
import {
	Button,
	Input,
	Container,
	Row,
	Col
} from 'reactstrap';
import $ from 'jquery';
import Swiper from 'react-id-swiper';
import './equationbox.css';
import {
	provider,
	auth,
	ref

} from '../../client';
//import Stopwatch from 'react-stopwatch';
import Loader from 'react-loader-spinner';
//import baseUrl from '../../environment/config.js';
//import { timingSafeEqual } from 'crypto';
import play from '../../Icons/play.png';
import pause from '../../Icons/pause.png';
import Fullscreen from 'react-full-screen';

class Equationbox extends Component {
	constructor(props) {
		super(props);

		this.availOperators = ['+', '-', '*', '/', '^'];
		this.rowIndex = 0;
		this.displayDataStore = [];
		this.percentage = 100;
		this.equationStore = [];
		this.gameOver = '';
		this.isPlay = true;
		this.item = 0;
		this.lastPosition = 0;
		this.lastAct = 0;
		this.count = 0;
        this.act = NaN;
		this.ps = 'lhs';
		this.rowInd = 0;
		this.direc = 'up';
		this.conditionMatch = true;
		this.gameLayerCount = 0;
		this.highlightRow = true;
		this.prevItem = 0;
		this.prevCount = 0;
		
		this.uid = 0;
		this.gameCount = 0;
		this.swapObj = {
			prevRow:'',
			rowId:'',
			equationId:'',
			dataId:''
		}
		this.selected = 0;
		this.state = {
			level: 0,
			score:0,
			rank: 0,
			moves: 0,
			isOn: false,
			start: 0,
			isLoggedIn: false,
			response: '',
			highlightFlag: false,
			percentSlider: this.percentage,
			popup:'',
			showPop: false,
			conf: true,
			startPop: true,
			highScore: 0,
			countDownTimer: 10,
			
			//selected: this.gameLayerCount,
		
			//-----HERE ARE NEW PARAMS------------
			showEquationStore: this.equationStore,
			showdataGrid : this.displayDataStore,
		};
		this.handleClickOutside=this.handleClickOutside.bind(this);
		this.setWrapperRef=this.setWrapperRef.bind(this);
		this.handleKeyFunction=this.handleKeyFunction.bind(this)
		this.startGame= this.startGame.bind(this);
		this.startTimer= this.startTimer.bind(this);
		this.cursorSelection = this.cursorSelection.bind(this);
		
		
	}
	countDown = 10;

	showStartPop(){
		 if(this.props.startPopGame === true){
			return(
				<div>
					<button className = "btn btn-default" onClick={()=>this.startGame()}>START</button>
					
				</div>
			)
		}else{
			
			this.startGame()
		 }
		
	}

	startGame(){
		
		this.displayDataStore = [];
		this.equationStore = [];
		this.percentage = 100;
		this.setState({
			percentSlider:this.percentage,
			startPop: false,
			
		});
		
		this.gameBufferCollection(1);
	
		this.generateSlot(this.rowIndex,'insert');
		this.startTimer();
		this.props.callbackFromParent(this.state);
		$(".progress-bar-container-wrap").css("visibility","visible");
	}

	
	/**
	 * Decrease percentage according to level
	 * @return numaric value
	 */
	getPercentageLevel(){
		var pr;
		if(this.state.level <= 10){
			pr = 0.13;
		}
		else if(this.state.level > 10 && this.state.level <= 20){
			pr = 0.16;
		}
		else if(this.state.level > 20 && this.state.level <= 30){
			 pr = 0.22;
		}
		else if(this.state.level > 30 && this.state.level <= 40 ){
			pr = 0.30;
		}
		else{
			pr = 0.50
		}
		return pr;
	}
	

	/**
	 * Start tick timer for the actions
	 */
	startTimer() {
		this.timer = setInterval(() => {
			
			if(this.gameLayerCount >= 7 && parseInt(this.percentage) === 0){
				clearInterval(this.timer);	
				
				const data={
					score : this.state.score,
					level : this.state.level
				};
				var updates = {};
				updates['/gameScore/'+this.gameCount] = data;
				
				if (localStorage.user) {
					
					//console.log(localStorage.user)
					 let uid = JSON.parse(localStorage.getItem('userLoggedIn')).uid
					 console.log(uid);
					 this.uid= uid;
					 ref.orderByChild("uid").equalTo(uid).on("child_added", (snapshot) => {
						 console.log(snapshot.val());
						 snapshot.ref.update(updates);
						 if(this.state.score >= this.state.highScore){
							var newup={};
						 	console.log(this.state.score) 
						 		this.setState({highScore:this.state.score});
						 	newup['/highScore']= this.state.score;
								snapshot.ref.update(newup);
						 }
						 
					
					})
				}else{
					this.setState({highScore : this.state.score});
				}
				
				
				this.setState({
					showPop:true,
					startPop: true,
					});
				this.displayDataStore='';
				
			}else if(parseInt(this.percentage) === 0) {
				
				this.resetTimer();
			}
			
			this.percentage = this.percentage - this.getPercentageLevel();
			if(parseInt(this.percentage)%10 === 0){
				this.countDown = parseInt(this.percentage)/10;
			}
			this.setState({ percentSlider:this.percentage,
				countDownTimer: this.countDown})
		
				 this.props.callbackFromParent(this.state);
						
		}, 10)
		
	}
	
	/**
	 * Reseting of ticktok timer
	 */
	resetTimer() {
		this.percentage = 100;
		this.countDown = 10;
		this.setState({
			percentSlider: this.percentage,
			countDownTimer: this.countDown
		}, function(){
			this.props.callbackFromParent(this.state);		
		})
		
		this.gameBufferCollection(1);
		this.rowIndex = this.rowIndex + 1;
		this.generateSlot(this.rowIndex , 'insert');
	}
	
	/**
	 * Show popover if the game is over and 7 most equation are out of given time alloted.
	 */
	pops(){
		this.state.popup = (
			<div className='popup'>
			  <div className='popup_inner'>
				<h1>Game Over!!!</h1>
			 
			  <Button onClick={()=>this.props.handleContinue()}>Reset</Button>
			  </div>
			</div>
		  );
		  this.setState({
			  popup:this.state.popup
			  
		});
		this.props.callbackFromParent(this.state);
	}
	

	/**
	 * Fires when the DOM is ready
	 */
	componentWillMount() {
		// Generate random number on view mount(load)
		if (localStorage.user) {
			//alert('user logged in')
			//console.log(localStorage.user)
			 let uid = JSON.parse(localStorage.getItem('userLoggedIn')).uid
			
			 this.uid= uid;
			 
			 ref.orderByChild("uid").equalTo(uid).on("child_added", (snapshot) => {
				this.gameCount= snapshot.val().gameCount;
								
				var updates ={}
				updates['/gameCount'] = this.gameCount+1; 
				snapshot.ref.update(updates);
				this.state.response = snapshot.val();
				this.state.isLoggedIn = true;
				console.log(snapshot.val().highScore);

				var score = snapshot.val().highScore;
				this.setState({highScore: score});
			// 	this.generateNumber();
			 })
		} else {
			//alert('no user logged')
			// if (!localStorage.level) {
			// 	localStorage.setItem('level', 1);
			// }
			// let level = JSON.parse(localStorage.getItem('level'));
			// this.state.level = level;
			// this.generateNumber();
		}
		
		// this.gameBufferCollection(1);
		// this.generateSlot(this.rowIndex,'insert');
		// this.startTimer();
		 this.props.callbackFromParent(this.state);
	}

	
	componentDidMount(){
		this.props.onRef(this)
		window.addEventListener('click',this.handleClickOutside)
		window.addEventListener('keydown',this.handleKeyFunction)
		
	}
	

	componentWillUnmount() {
		//this.props.onRef(undefined)
		clearInterval(this.timer);	
		window.removeEventListener('click',this.handleClickOutside)
		window.removeEventListener('keydown',this.handleKeyFunction)
	}

	/**
	 * clean first click temporary data when click outside equation
	 * @param {*} event 
	 */
	handleClickOutside(event){
		
		if(this.wrapperRef && !this.wrapperRef.contains(event.target)){
			
			this.setState({
				highlightFlag:false
			})
			this.swapObj.prevRow='';
			this.swapObj.dataId='';
			this.swapObj.equationId='';
			this.swapObj.rowId='';
		}
				
	}

	/**
	 * setting Ref variable for DOM element
	 * @param {*} node 
	 */
	setWrapperRef(node){
		this.wrapperRef=node;
	}

	/**
	 * Get the active Index as we are prepanding DOM so its in LIFO order 
	 * @param {*} index 
	 */
	getSlotIndex(index){
		 for(var i = 0; i < (this.displayDataStore).length; i++){
			if(this.displayDataStore[i].key === 'equationKey_'+index){
				return i;
				break;
			}
		 }
	}
	
	/**
	 * Generate Operator and Operand boxes {key} to be unique
	 * @param {*} indexVal 
	 * @param {*} state
	 * @returns DOM
	 */
  	generateSlotBoxes(indexVal , state){
		let slotArr = [];
		var i = 0, lhs, rhs;
		var dataStore = this.equationStore[indexVal];
		var el = this;
		
		$.each(dataStore, function( gIndex, dataSide ) {
			$.each(dataSide,function(index,val){
				if (index % 2 === 0) {
					slotArr.push(<div id={'box_'+indexVal+'_'+i} key={'boxkey_'+indexVal+'_'+i}  className="equationRow equationNumber" onClick={() => el.swapNumbers(gIndex, indexVal, index)} >{ val }</div>)
				}else{
					slotArr.push(<div id={'box_'+indexVal+'_'+i} key={'boxkey_'+indexVal+'_'+i} className="equationRow equationRowOperator " onClick={(e) => el.changeOperator(gIndex, indexVal, index, val)}>{ val }</div>)
				}
				i++;
			});

			//Operator equal
			if(i === 3){
				var sign = (el.gameLayerCount === 0 || state  === 'update') ? "≠" : "";
				var solveCls = (el.gameLayerCount === 0 || state === 'update') ? "unsolved" : "";
				slotArr.push(<div id={'box_'+indexVal+'_'+i} key={'boxkey_'+indexVal+'_'+i} className={ 'equationRow eqautionbox_equals '+ solveCls } onClick={()=>el.handleEqual()}>{sign}</div>)
				i++;
			}
		});
		return slotArr;
	}
	
	/**
	 * Generate equation row along with equation boxes
	 * @param {*} index
	 * @param {*} state 
	 */
	generateSlot(index, state) {
		var equationDOM = "";
		var equationBoxView = <div id={'equationBox_'+index} key={'equationKey_'+index} className="equationBox slideInDown animated">
			{this.generateSlotBoxes(index, state)}
		  </div>
		if (localStorage.user) {
			if (this.state.response) {
				equationDOM = equationBoxView
			} else {
				equationDOM = <div className="equationBox">
					<span className="loader_text">Please wait while we are loading Your game data</span>
					<Loader type="Watch" color="#d1d1d1" height="30" width="30" />
				</div>
			}
		} else {
			equationDOM = equationBoxView
		}

		
		//this.displayDataStore.push(equationBoxView); //Append New row - No longer use
		if(this.displayDataStore[index] !== undefined) {
			var activeIndex = this.getSlotIndex(index);
			//console.log("Update->"+index+': active index'+activeIndex);
			this.displayDataStore[activeIndex] = equationBoxView;
		}else{
			//console.log("Insert"+index);
			this.displayDataStore.unshift(equationBoxView); //prepend New row
			this.gameLayerCount++;
			//console.log('increment game', this.gameLayerCount);
           
		}
		
		//console.log(this.displayDataStore);
		this.setState({
			showdataGrid : this.displayDataStore,
			//gameLayerCount: this.state.gameLayerCount
		});
		this.props.callbackFromParent(this.state);
	}

	

	/**
	 * Generate a min and max number according to level
	 * @returns minMax object
	 */
	getMinMaxNumber(){
		var minMax = {};

		if(this.state.level <= 10){
			minMax = {min : 0, max : 9}
		}
		else if(this.state.level > 10 && this.state.level <= 20){
			minMax= {min : 10, max : 19}	
		}
		else if(this.state.level > 20 && this.state.level <= 30){
			minMax = {min : 20, max : 29}
		}
		else if(this.state.level > 30 && this.state.level <= 40){
			minMax = {min : 30, max : 49}
		}
		else{
			minMax = {min : 50, max : 99}
		}

		return minMax;
	}

	/**
	 * Check if the equation already matched with the provided batch.
	 * @param {*} lhsEquation 
	 * @param {*} rhsEquation 
	 */
	equationMatched(lhsEquation, rhsEquation){
		var lhs=this.calculate(lhsEquation[0], lhsEquation[2], lhsEquation[1])
		var rhs=this.calculate(rhsEquation[0], rhsEquation[2], rhsEquation[1])
		if(lhs === rhs){
			return true;
		}
		return false;				
	}

	/**
	 * generate random number according to level
	 * @returns random number
	 */
	generateRandomNumber(){
		var obj = this.getMinMaxNumber();
		return Math.floor(Math.random()*(obj.max - obj.min + 1) + obj.min);
	}

	/**
	 * To generate equation for the LHS(Left hand side) contains all number and operators
	 * @returns array
	 * @param
	 */
	generateEquationLHS() {
		var eq = [];
		eq[0] = this.generateRandomNumber();
		eq[1] = this.selectOperator(1);
		eq[2] = this.generateRandomNumber();
		return eq;
	}

	/**
	 * To generate equation for the RHS(right hand side) contains all number and operators
	 * @returns array
	 * @param LHS array store to make solvable equation
	 */
	generateEquationRHS(lhsStore) {
		var eq = [], num;
		var lhsEQ = this.calc(lhsStore[0], lhsStore[2], lhsStore[1]);
		eq[0] = this.generateRandomNumber();
		eq[1] = this.selectOperator(2);
		eq[2] = 0
		//Calculate on the basis of LHS and first RHS index vals with operators
		var rhsEQ = this.calc(lhsEQ, eq[0], eq[1]);
		
		num=this.getMinMaxNumber()
		//console.log(num.max)
		if (rhsEQ <= num.max && rhsEQ >= num.min && Number.isInteger(rhsEQ)) {
			eq[2] = rhsEQ;
		}
		return eq;
	}

	/**
	 * To combile genarated equation into one obj, if Equation does not meet to solvable number then again  * fire the same function until it get match. (recursive version)
	 * @param 
	 * @return object 
	 */
	compileBothSideEquations() {
		var compiledEqStore = {};

		//Swap left and right equation on the basis of random function - return 1 or 2 all time 
		var eqSide1 , eqSide2, isEqValid; 
		var eqPos = Math.floor(Math.random()*(2 - 1 + 1) + 1);
		if(eqPos === 1){
			eqSide1 = this.generateEquationLHS();
			eqSide2 = this.generateEquationRHS(eqSide1);
			isEqValid = eqSide2[2]; 
		}else{
			eqSide2 = this.generateEquationLHS();
			eqSide1 = this.generateEquationRHS(eqSide2);
			isEqValid = eqSide1[2];
		}

		//If RHS second operand is 0 (do recursion)
		if (isEqValid === 0 || this.equationMatched(eqSide1 , eqSide2) === true) { 
			return this.compileBothSideEquations();
		} else {
			compiledEqStore.lhs = eqSide1;
			compiledEqStore.rhs = eqSide2;
		}
		return compiledEqStore;
	}

	/**
	 * This function create number of rows with all rows at once but shows one by one with time
	 * @param {*} n 
	 * @return
	 */
	gameBufferCollection(n) {
		for (var i = 0; i < n; i++) {
			var dtStore = this.compileBothSideEquations();
			this.equationStore.push(dtStore);
			this.setState({
				showEquationStore: this.equationStore
			});
		}
	}

	/**
	 * Select random operator from a given set of operators
	 * @param {*} set 
	 * @returns operators(String)
	 */
	selectOperator(set) {
		if (set === 1) {
			var operatorsSet = [{
					sign: "+"
				},
				{
					sign: "-"
				},
				{
					sign: "*"
				},
				{
					sign: "/"
				},
				{
					sign: "^"
				},
			];
		} else {
			var operatorsSet = [{
					sign: "+"
				},
				{
					sign: "-"
				},
				{
					sign: "*"
				},
				{
					sign: "/"
				},
				/*{
					sign: "log"
				},*/
			];
		}
		var selectedOperator = Math.floor(Math.random() * operatorsSet.length);
		return operatorsSet[selectedOperator].sign;
	}

	/**
	 * For algo calculation: Make sure our equation is solvable
	 * @param {*} val1 
	 * @param {*} val2 
	 * @param {*} operator 
	 * @returns integer
	 */
	calc(val1, val2, operator) {
		var result;
		switch (operator) {
			case "*":
				result = val1 * val2
				break;
			case "+":
				result = val1 + val2
				break;
			case "-":
				result = val1 - val2
				break;
			case "/":
				result = val1 / val2
				break;
			case "^":
				result = val1 ** val2
				break;
			case "log":
				result = Math.log(val1) / Math.log(val2);
				break;
		}
		return result;
	}

	/**
	 * For calculating RHS and LHS based values for equation submit 
	 * @param {*} val1 
	 * @param {*} val2 
	 * @param {*} operator 
	 * @returns
	 */
	calculate(val1, val2, operator) {
		var result;
		switch (operator) {
			case "*":
				result = (parseInt(val1) * parseInt(val2))
				break;
			case "+":
				result = (parseInt(val1) + parseInt(val2))
				break;
			case "-":
				result = (parseInt(val1) - parseInt(val2))
				break;
			case "/":
				result = (parseInt(val1) / parseInt(val2))
				break;
			case "^":
				result = (parseInt(val1) ** parseInt(val2))
				break;
			default:
				result = (parseInt(val1) * parseInt(val2))
				break;
		}
		return result;
	}
	/**
	 * Handle keyboard key press functions
	 * @param {*} e 
	 */
	handleKeyFunction(e){
		e.preventDefault();
		
		switch(e.key){
			case 'ArrowUp':
				this.act = this.lastPosition;
				this.direc = 'up';
				
				//console.log('ArrowUp-->>   ',this.selected)
				if(this.selected < this.equationStore.length-1){
					++this.selected;
					//console.log('ArrowUp-->>   ',this.selected)
					this.cursorSelection(this.ps, this.selected, this.lastPosition, this.direc);
				}
				  
				break;
			case 'ArrowDown':
				
				this.act = this.lastPosition;
				this.direc = 'down';
				
				//console.log('ArrowDown-->>  ',this.selected)
				if(this.selected >=0 ){
					--this.selected
					if(this.selected === -1){
						this.selected = 0;
					}
					//console.log('ArrowDown-->>  ',this.selected)
					 this.cursorSelection(this.ps, this.selected, this.lastPosition, this.direc);
				}
				
				break;
			case 'ArrowLeft':
			//console.log(' ArrowLeft Key ', this.selected)
				if(this.selected >= 0 && this.highlightRow === true)
				{	
					var pos = Object.keys(this.equationStore[this.selected]);
				    if(this.prevCount >= 5){
						if(this.prevItem === 3){
							this.prevItem = 1
						}
						--this.prevCount;
						
						this.digitSelectionReverse(pos[1],this.selected)
						
					}
					else if(this.prevCount > 0){
						
						if(this.prevItem === 0 && this.prevCount === 4){
							this.prevItem = 3;
							this.prevCount--;
							}
							
						this.digitSelectionReverse(pos[0],this.selected)
						this.prevCount--;
						
					}
										
				 }else{
					 //alert('Please select a digit first');
				 }
				 
				
				break;
			case 'ArrowRight':

				if(this.selected >= 0 && this.highlightRow=== true)
					{ 	
						//console.log(' ArrowRight Key ', this.selected)
						var pos = Object.keys(this.equationStore[this.selected]);
						if(this.count <=2){
							this.digitSelection(pos[0],this.selected)
							this.count ++;
						}
						else if(this.count<6) {
							this.count++ ;
							this.digitSelection(pos[1],this.selected)
						}
					}else{
						//alert('Please select a Row first using up and down arrow key')
					}
			break;
			case ' ':
			
				if(isNaN(this.act)){
					return ;
				}else{
					
					if(this.act === 1){
						var op=this.equationStore[this.selected][this.ps][this.act];
						this.changeOperator(this.ps, this.selected, this.act, op);
					}else{
						this.swapNumbers(this.ps, this.selected, this.act);
					}
				}
				
               break;
			case 'Enter':
			if(this.state.startPop === true ){
				
				this.startGame();
			 }
			break;
			case 'Shift':
			if(this.state.startPop === false){
				this.playPause(e);
			}
			 
			 break;
		}
	}
/**
 * Right arrow key handle function
 * @param {*} pos 
 * @param {*} rowIndex 
 */
	digitSelection(pos, rowIndex){
		
		if(pos === 'rhs' && this.item === 3){
			 	this.item = 0;
		}
		
		if(this.item < 3){
			this. act = this.item;
			this.ps = pos;
			
			this.rowInd = rowIndex;
			this.lastPosition = this.item;
			this.prevCount = this.count;
			this.prevItem = this.item;
			this.cursorSelection(pos, rowIndex, this.item);
			
			this.item++;
			
			
		}else{
			this.item = 0;
		}
	}

	/**
	 * Left arrow key handle Function
	 * @param {*} pos 
	 * @param {*} rowIndex 
	 */
	digitSelectionReverse(pos, rowIndex){
		
		if(this.prevItem > 0){
			this.ps=pos;
			this.rowInd = rowIndex;
			this.item = this.prevItem;
			this.count = this.prevCount;
			this.prevItem--;
			
			this.cursorSelection(pos, rowIndex , this.prevItem)
			this.lastPosition = this.prevItem;
			this.act=this.prevItem;
		}else{
			this.prevItem = 2;
			}
		
		
	}
	/**
	 * For cursor movement 
	 * @param {*} pos
	 * @param {*} rowIndex
	 * @param {*} boxIndex 
	 */
	cursorSelection(pos, rowIndex, boxIndex, direction){
		
		this.highlightRow = true;
		var target = $("#equationBox_"+rowIndex);
		
		if(target.hasClass("remove")){
			if(direction === 'up'){
					++this.selected
					
					this.cursorSelection(pos, rowIndex+1, boxIndex, direction);
					
											
			}else if(direction === 'down'){
				if(rowIndex-1 < 0){
					return false
				}else{
					this.cursorSelection(pos, rowIndex-1, boxIndex, direction);
					--this.selected
					if(this.selected <= 0){
						this.selected = 0;
					}
				}
			}
		  return;
		}
		$(".equationBox").each(function(){
			$(this).removeClass("highlight-row");
			$(this).find('.equationRow').removeClass("cursor-box");
			$(this).find(".eqautionbox_equals").html(" ");
		});
		target.find(".eqautionbox_equals").html("≠").removeClass("unsolved").addClass("unsolved");
		target.addClass("highlight-row");
		if(pos === 'lhs'){
			var bi = (boxIndex === undefined) ? 1 : boxIndex;
			target.find('.equationRow').eq(bi).addClass("cursor-box");
		}else{
			var bi = (boxIndex === undefined) ? 5 : (boxIndex + 4);
			target.find('.equationRow').eq(bi).addClass("cursor-box");
		}
		if(boxIndex !== 1){
			target.find(".equationNumber:not('.cursor-box')");
		}
		
		
	}

	/**
	 * Remove match row and setting up or reset timer index, maybe with animation.
	 * @param {*} indexVal 
	 * @returns void
	 */
	equationSubmit(indexVal){
		
		var target = $("#equationBox_"+indexVal);
		var el = this;
		target.find(".eqautionbox_equals").html("=");
		target.find(".eqautionbox_equals").removeClass("unsolved").addClass("solved");
		
		//Pass lebel info to parent
		el.state.level+=1
		el.setState({level:el.state.level});
		
		if(el.gameLayerCount <= 7){
			 --el.gameLayerCount
		}
		//check if the screen is blank, call new set ASAP
		
		if(el.gameLayerCount === 0){
			el.percentage = 0;
		}
		//console.log('decrementing---> gameLayer', el.gameLayerCount);
		el.setState({
			percentSlider:el.percentage,
			
		})
		el.props.callbackFromParent(el.state);
		
		el.highlightRow = false;
		//el.rowInd =indexVal;
		setTimeout(function(){
			//target.removeClass("slideInDown").addClass("fadeOutLeft");
			target.animate({
				left: "-390px"
			},600,function() {
				target.slideUp(200).removeClass("slideInDown").addClass("remove");
				
			
					if(target.nextAll(":not('.remove')").first().length > 0){
						//console.log("next");
						
							var blankRow = el.checkNextBlankRow();
							console.log('return value',blankRow)
							if(blankRow === true){
								
								target.nextAll(":not('.remove')").first().find(".eqautionbox_equals").html("≠").addClass("unsolved");
								var a = $(".unsolved").closest(".equationBox").attr("id");
								var b = a.split('_')
								el.selected = parseInt(b[1]) 
								
								el.cursorSelection(el.ps, el.selected, el.lastPosition);
							}else{
								
								el.cursorSelection(el.ps, el.selected, el.lastPosition);
							}

						}else{
						//console.log("prev");
						target.prevAll(":not('.remove')").first().find(".eqautionbox_equals").html("≠").addClass("unsolved");
						var a = $(".unsolved").closest(".equationBox").attr("id");
						var b = a.split('_')
						
						el.selected = parseInt(b[1]) 
						el.cursorSelection(el.ps, el.selected, el.lastPosition, el.direc);	
					}
			
				
			});
			
		},800);

	}
/**
 * check for the next row to be highlighted
 * @returns true
 */
	checkNextBlankRow(){
		
		var blankCount = 0;
		var t;
		$(".equationBox:not(.remove)").each(function(){
			if($(this).find(".eqautionbox_equals").html() === ''){
				blankCount++;
			 }
		});
			
		if(blankCount === $(".equationBox:not(.remove)").length){
			return true
		}else{
			return false;
		}
		
	}

	/**
	 * Calculate score and return the absolute value
	 * @param {*} lhs 
	 * @param {*} rhs 
	 */
	calculateScore(lhs, rhs){
		var s = Math.ceil(this.state.score);
		var l = Math.ceil(Math.abs(lhs));
		var newScore = Math.abs(s + l);
		return newScore;	
	}

	/**
	 * Cheack wather the equation is equal to borh side LHS=RHS
	 * @param {*} indexVal
	 * @returns void 
	 */
	checkEquation(indexVal){
		var dataStore = this.equationStore[indexVal];
		var lhs, rhs;
		for( var key in dataStore){
			if(key ==='lhs'){
				lhs=this.calculate(dataStore[key][0], dataStore[key][2], dataStore[key][1])
			}
			else{
				rhs=this.calculate(dataStore[key][0], dataStore[key][2], dataStore[key][1])
			}
		}
		if(lhs === rhs){
			
				//var target = $("#equationBox_"+indexVal);
				var el = this;
				// target.find(".eqautionbox_equals").html("=");
				// target.find(".eqautionbox_equals").removeClass("unsolved").addClass("solved");
				el.conditionMatch = true;
				setTimeout(()=>{
					if(el.conditionMatch === true){
						el.conditionMatch = false;
						el.equationSubmit(indexVal);
						var calculateScore = el.calculateScore(lhs, rhs);
						el.state.score=calculateScore;
						
					}
				},1000);
				
				el.setState({score:el.state.score});
				el.props.callbackFromParent(el.state);
		}
	}
	
	/**
	 * Make current row active
	 * @param {*} gindex 
	 */
	currentSelection(pos, rowIndex, boxIndex){
		//console.log("here index"+ gindex);
		var target = $("#equationBox_"+rowIndex);
		// if(target.find(".eqautionbox_equals").hasClass("solved")){
		// 	return false;
		// }
		$(".equationBox").each(function(){
				$(this).removeClass("highlight-row");
				$(this).find('.equationRow').removeClass("highlight-box").removeClass("highlight-yellow");
				$(this).find(".eqautionbox_equals").html("");
		});
		
		target.find(".eqautionbox_equals").html("≠").removeClass("unsolved").addClass("unsolved");
		target.addClass("highlight-row");
		

		if(pos === 'lhs'){
			var bi = (boxIndex === undefined) ? 1 : boxIndex;
			target.find('.equationRow').eq(bi).addClass("highlight-box");
		}else{
			var bi = (boxIndex === undefined) ? 5 : (boxIndex + 4);
			target.find('.equationRow').eq(bi).addClass("highlight-box");
		}
		if(boxIndex !== 1){
			target.find(".equationNumber:not('.highlight-box')").addClass("highlight-yellow");
		}
	}

	/**
	 * Swaping of operands between each other (Not for operators)
	 * @param {*} pos 
	 * @param {*} rowIndex 
	 * @param {*} boxIndex 
	 * @return void
	 */
	swapNumbers(pos, rowIndex, boxIndex){
		
        this.currentSelection(pos, rowIndex, boxIndex);
       
		if(this.state.highlightFlag === false){
			this.setState({
				highlightFlag:true
            });
            this.swapObj.prevRow = this.equationStore[rowIndex];
			this.swapObj.dataId = boxIndex;
            this.swapObj.equationId = pos;
			this.swapObj.rowId = rowIndex;
			 
		}else{
			this.setState({
				highlightFlag:false
			});
			if(this.swapObj.rowId === rowIndex){
				var dataStore = this.equationStore[rowIndex];
				var prevRowData = this.swapObj.prevRow[this.swapObj.equationId][this.swapObj.dataId];
				this.swapObj.prevRow[this.swapObj.equationId][this.swapObj.dataId]=dataStore[pos][boxIndex];
				dataStore[pos][boxIndex] = prevRowData;
				this.equationStore[rowIndex] = dataStore;
				this.setState({
					showEquationStore:this.equationStore
				},function(){
					var el = this;
					var target = $("#equationBox_"+rowIndex);
					target.find('.equationRow').removeClass("highlight-box").removeClass("highlight-yellow");
					el.generateSlot(rowIndex, 'update');
				});
				setTimeout(()=>{
					this.checkEquation(rowIndex);
				},100);
				
				
			 }else{
				
				this.swapObj.prevRow = '';
				this.swapObj.dataId = '';
				this.swapObj.equationId = '';
				this.swapObj.rowId = '';
			}
		}	
	}

	/**
	 * Changing of operators to new one on click on operators button only
	 * @param {*} pos 
	 * @param {*} index 
	 * @param {*} operator 
	 */
	changeOperator(pos, indexVal, index,operator) {
		//console.log('change Operator')
		var el = this;
		el.currentSelection(pos, indexVal, index);
		// var target = $("#equationBox_"+indexVal);
		// target.find(".eqautionbox_equals").html("≠");
		// target.find(".eqautionbox_equals").removeClass("solved").addClass("unsolved");
		el.conditionMatch = false;
		
		var successorOperator = el.availOperators.indexOf(operator);
		var SOI = ((el.availOperators.length - 1) === successorOperator) ? 0 : successorOperator+1;
		el.equationStore[indexVal][pos][1] = el.availOperators[SOI];
		el.setState({
			showEquationStore : el.equationStore
		},function(){
			el.generateSlot(indexVal, 'update');
		});
		
		setTimeout(()=>{
			this.checkEquation(indexVal);
		},100);
		this.setState({
			highlightFlag:false
		});
		el.swapObj.prevRow = '';
		el.swapObj.dataId = '';
		el.swapObj.equationId = '';
		el.swapObj.rowId = '';
	}

	/**
	 * clean temporary data of first click for swap on click equal sign
	 */
	handleEqual(){
		this.setState({
			highlightFlag:false
		});
		this.swapObj = {
			prevRow: "",
			dataId:"",
			equationId: "",
			rowId: ""
		};
	}

	playPause(el){
		if(this.isPlay){
			this.isPlay = false;
			clearInterval(this.timer); //Pause
	$(".equatiobox-outer").append('<div class="eq-overlay"> <div class="battery_charging"><div class="battery_charging_inner"><span>Charging Battery</span></div></div></div>');
			$("button.play-pause-btn").removeClass("uim-btn-play").addClass("uim-btn-pause");
		}else{
			this.isPlay = true;
			this.startTimer(); //Play
			$(".equatiobox-outer").find(".eq-overlay").remove();
			$("button.play-pause-btn").removeClass("uim-btn-pause").addClass("uim-btn-play");
		}
	}

	/**
	 * Rendering of DOM shows all equations in the form of matrix
	 * @param
	 * @returns DOM/HTML
	 */
	render() {
		const styles = {
			containerOutter: {
				width: 'auto', height: 'auto', background: 'none', border: 'none'
			},
			containerInner: {
				lineHeight: '0'
			}
		}
		// const ProgressBar = (props) => {
			
		// 	return (
		// 		<div className="progress-bar">
		// 			<Filler percentage={props.percentage} />
		// 		</div>
		// 	)
		// }
		// const Filler = (props) => {
		// 	return <div className="filler" style={{ width: `${props.percentage}%` }} />
		// }
		const operator = (availOperators) => {
			let operators = [];
			for (let i = 0; i < availOperators.length; i++) {
				operators.push(<div key={i}>
					<div>{availOperators[i]}</div>
				</div>)
			}
			return operators;
		}
		// -- remove this
		const numbers = (availNumbers) => {
			let numbers = [];
			for (let i = 0; i < availNumbers.length; i++) {
				numbers.push(<Input type="text" disabled key={i} value={availNumbers[i]} />)
			}
			return numbers;
		}
		
		return (
			<div>
				 <Container className="equationbox_container">
					<Row>
						<Col className="">
							{/* <div className="progress-bar-container-wrap">
								<Row className="d-flex justify-center align-items-center">
									<Col className="text-center progressbar_outer">
									   <ProgressBar percentage={this.percentage} /> 
										
									</Col>
									 <button className="play-pause-btn uim-btn-play" onClick={(e) => this.playPause(e)}></button> 
								</Row>
							</div> */}
							 
							<div className="equatiobox-outer mb-30" >
								<div  className="equationbox-inner" id="equationbox-box-inner" ref={this.setWrapperRef} >
									{this.state.startPop===true ? this.showStartPop() : this.displayDataStore }	
									{/* {this.gameOver} */}
								
								</div>
								
							</div>
							
						</Col>
					</Row>
				</Container>
				<Container className="instructions_container">
					<Row>
						<Col>
							<div className="instruction-wrapper hide-mobile">
								<b>How to play:</b> Lorem was popularised in the 1960s with the release of Letraset sheet. Popularised in the 1960s
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default Equationbox;