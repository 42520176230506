import * as firebase from 'firebase';


var app = firebase.initializeApp({
	apiKey: "AIzaSyCxlKDl2HbyIb2C2UN_0MkaDL7NujFkFXY",
  	authDomain: "bitsat-3f060.firebaseapp.com",
  	databaseURL: "https://bitsat-3f060.firebaseio.com",
  	projectId: "bitsat-3f060",
  	storageBucket: "",
  	messagingSenderId: "51131831350"
});

export const ref = firebase.database().ref('users');
export const auth = firebase.auth

