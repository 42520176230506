import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
// css
import './leaderboard.css';
import avatar from '../../Icons/avatar.png';
import { Scrollbars } from 'react-custom-scrollbars';

class Leaderboard extends Component {
	constructor(props) {
    	super(props);
    	this.state = {};
    }
  	render(){
  		return(
  			<div>
          <div className="leadersboard">
            <h2 className="px-3">Top Players</h2>
            <Row className="mx-0">
              <Col className="d-flex justify-content-center align-items-end px-1" xs="4">
                <div className="leader leader2">
                  <div className="leader_avatar">
                    <img src={avatar} className="" alt="logo" />
                  </div>
                  <div>Player 2</div>
                  <div>432545656</div>
                </div>
              </Col>
              <Col className="d-flex justify-content-center align-items-end px-1" xs="4">
                <div className="leader leader1">
                  <div className="leader_avatar">
                    <img src={avatar} className="" alt="logo" />
                  </div>
                  <div>Player 1</div>
                  <div>4561115</div>
                </div>
              </Col>
              <Col className="d-flex justify-content-center align-items-end px-1" xs="4">
                <div className="leader leader3">
                  <div className="leader_avatar">
                    <img src={avatar} className="" alt="logo" />
                  </div>
                  <div>Player 3</div>
                  <div>4151551</div>
                </div>
              </Col>
            </Row>
            <Row className="leaderboard_heading mx-0">
              <Col className="px-1" xs="4">Rank</Col>
              <Col className="px-1" xs="4">Name</Col>
              <Col className="px-1" xs="4">Score</Col>
            </Row>
                <div className="leadersranking">
                  <Scrollbars 
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>} 
                    style={{ height: 190 }}>
                    <Row className="leader_row">
                      <Col className="leader_rank" xs="4">
                        <div className="leader_rank">1</div>
                      </Col>
                      <Col xs="4">
                        <div className="leader_name">
                          <div className="leader_avatar">
                            <img src={avatar} className="" alt="logo" />
                          </div> Player Player 1
                        </div>
                      </Col>
                      <Col xs="4">
                        <div className="leader_score">
                          5151541
                        </div>
                      </Col>
                    </Row>
                    <Row className="leader_row">
                      <Col className="leader_rank" xs="4">
                        <div className="leader_rank">2</div>
                      </Col>
                      <Col xs="4">
                        <div className="leader_name">
                          <div className="leader_avatar">
                            <img src={avatar} className="" alt="logo" />
                          </div> Player 2
                        </div>
                      </Col>
                      <Col xs="4">
                        <div className="leader_score">
                          5151541
                        </div>
                      </Col>
                    </Row>
                    <Row className="leader_row">
                      <Col className="leader_rank" xs="4">
                        <div className="leader_rank">3</div>
                      </Col>
                      <Col xs="4">
                        <div className="leader_name">
                          <div className="leader_avatar">
                            <img src={avatar} className="" alt="logo" />
                          </div> Player 3
                        </div>
                      </Col>
                      <Col xs="4">
                        <div className="leader_score">
                          5151541
                        </div>
                      </Col>
                    </Row>
                    <Row className="leader_row" xs="4">
                      <Col className="leader_rank">
                        <div className="leader_rank">4</div>
                      </Col>
                      <Col xs="4">
                        <div className="leader_name">
                          <div className="leader_avatar">
                            <img src={avatar} className="" alt="logo" />
                          </div> Player 4
                        </div>
                      </Col>
                      <Col xs="4">
                        <div className="leader_score">
                          5151541
                        </div>
                      </Col>
                    </Row>
                    <Row className="leader_row">
                      <Col className="leader_rank" xs="4">
                        <div className="leader_rank">5</div>
                      </Col>
                      <Col xs="4">
                        <div className="leader_name">
                          <div className="leader_avatar">
                            <img src={avatar} className="" alt="logo" />
                          </div> Player 5
                        </div>
                      </Col>
                      <Col xs="4">
                        <div className="leader_score">
                          5151541
                        </div>
                      </Col>
                    </Row>
                    <Row className="leader_row">
                      <Col className="leader_rank" xs="4">
                        <div className="leader_rank">6</div>
                      </Col>
                      <Col xs="4">
                        <div className="leader_name">
                          <div className="leader_avatar">
                            <img src={avatar} className="" alt="logo" />
                          </div> Player 6
                        </div>
                      </Col>
                      <Col xs="4">
                        <div className="leader_score">
                          5151541
                        </div>
                      </Col>
                    </Row>
                  </Scrollbars>
                </div>
          </div>
        </div>	
  		)
  	}
}

export default Leaderboard;